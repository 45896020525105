<template>
  <div>
    <div class="warp" v-if="isShowdata">
      <div class="item-list" v-for="(item, index) in itemList" :key="index">
        <div class="top">
          <img :src="item.url" alt="" />
          <span>{{ item.name }}</span>
        </div>
        <p>{{ item.ramk }}</p>
        <div class="btn">
          <el-button
            type="primary"
            size="small"
            plain
            @click="JumpClick(index)"
            >{{ item.btn }}</el-button
          >
        </div>
      </div>
    </div>
    <div class="warps" v-else>
      <h2>您好，欢迎进入平治停车云平台</h2>
    </div>
  </div>
</template>

<script>
  import { pkInfoList, getSysAdminCert, pkInfoId } from '@/api/Parkinglot.js';
  import { getCurrentSysAdmin } from '@/api/common.js';
  export default {
    data() {
      return {
        isShowdata: true,
        itemList: [
          {
            url: require('@/assets/images/jcxf.png'),
            name: '进出记录',
            btn: '去查看',
            ramk:
              '可以查看管辖范围的停车场的所有进出记录，包括临时车和包月包年的车辆的进出记录。',
          },
          {
            url: require('@/assets/images/byyh.png'),
            name: '停车记录',
            btn: '去管理',
            ramk:
              '可以查看管辖范围的停车场的所有停车记录，包括临时车和包月包年的车辆的停车记录。',
          },
          {
            url: require('@/assets/images/ddjl.png'),
            name: '订单记录',
            btn: '去查看',
            ramk: '可以查看管辖范围的停车场的所有订单缴费记录。',
          },
          {
            url: require('@/assets/images/jsdz.png'),
            name: '结算对账',
            btn: '去查看',
            ramk:
              '可以查看管辖范围的停车场的支付对账信息可以按照信息查询和导出每天的汇总信息和结算明细记录。',
          },
        ],
        query: {
          // 查询条件
          current: 1,
          size: 10,
        },
      };
    },
    created() {
      this.getCurrentSysAdmin();
    },
    methods: {
      getCurrentSysAdmin() {
        // 查询登入用户信息
        getCurrentSysAdmin().then((res) => {
          if (res.data.code === '200') {
            if (res.data.data.roleIdList[0] == 3) {
              this.isShowdata = false;
            }
          }
        });
      },
      pkInfoList() {
        // 列表
        pkInfoList(this.query).then((res) => {
          // 停车场list
          if (res.data.data.list.length == 1) {
            const subDomain = res.data.data.list[0].subDomain;
            getSysAdminCert({ pkId: res.data.data.list[0].id }).then((res) => {
              // 停车场车主
              if (res.data.code === '200') {
                const sign = res.data.data.sign;
                window.open(
                  'http://' +
                    subDomain +
                    '.pingzhi.com:9000/Login?sign=' +
                    sign +
                    '&' +
                    'sy=' +
                    true
                );
              }
            });
          } else {
            this.$router.push({ path: '/Parking' });
          }
        });
      },
      JumpClick(index) {
        // 跳转
        if (index === 0) {
          this.$router.push({ path: '/Inandout' });
        } else if (index === 1) {
          // this.pkInfoList();
          this.$router.push({ path: '/Parking' });
        } else if (index === 2) {
          this.$router.push({ path: '/Neworder' });
        } else if (index === 3) {
          this.$router.push({ path: '/Billdaysummary' });
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .warps {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 80px);
  }
  .warp {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100vh - 80px);
    padding: 100px 200px;
    box-sizing: border-box;
    background-color: #f5f5f5;
    .item-list {
      position: relative;
      width: 45%;
      height: 45%;
      padding: 20px;
      box-sizing: border-box;
      background-color: #ffffff;
      .top {
        display: flex;
        align-items: center;
        span {
          margin-left: 10px;
          font-size: 20px;
          font-weight: 600;
        }
      }
      p {
        color: #adadad;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .btn {
        position: absolute;
        left: 0;
        bottom: 10px;
        width: 100%;
        text-align: center;
      }
    }
  }
</style>
